
// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export type CssGlobalValues        = 'inherit' | 'initial' | 'unset';
// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export type CssBoxSizing           = 'content-box' | 'border-box';
export type CssFontStyle           = 'normal' | 'italic' | 'oblique'; // <- also oblique <angle>, eg. "oblique 10deg"
export type CssFontWeight          = 'normal' | 'bold' | 'bolder' | 'lighter' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';
export type CssOverflowValues      = 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto' | 'hidden visible' | CssGlobalValues;
export type CssPositionValues      = 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
export type CssTextAlignValues     = 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
export type CssUserSelectValues    = 'auto' | 'text' | 'none' | 'contain' | 'all';
export type CssVerticalAlignValues = 'baseline' | 'bottom' | 'initial' | 'inherit' | 'middle' | 'sub' | 'super' | 'text-bottom' | 'text-top' | 'top'; // also length or %
export type CssVisibilityValues    = 'visible' | 'hidden' | 'collapse';
// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export type InputEnterKeyHintValue = 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send';

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
function pc2str(v: number): string { return (v !== 0) ? `${v}%`  : '0'; }
function px2str(v: number): string { return (v !== 0) ? `${v}px` : '0'; }

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export abstract class Element {
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public abstract get domEl(): HTMLElement;

  //private static _nextUid: number = 1;
  //constructor(el: HTMLElement) { if (el && !el.hasAttribute("uid")) { el.setAttribute("uid", (Element._nextUid++).toString()); } }
  //public get uid(): string { return this.domEl.getAttribute("uid") || ''; }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public append(e: Element):                        this { this.domEl.appendChild (e.domEl);                return this; }
  public insert(e: Element, eBefore: Element):      this { this.domEl.insertBefore(e.domEl, eBefore.domEl); return this; }
  public remove(e: Element):                        this { this.domEl.removeChild (e.domEl);                return this; }
  
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public attr(name: string, value: string):         this { this.domEl.setAttribute(name, value); return this; }
  public className         (value: string):         this { this.domEl.className   =      value;  return this; }
  public classList_add     (value: string):         this { this.domEl.classList.add     (value); return this; }
  public classList_remove  (value: string):         this { this.domEl.classList.remove  (value); return this; }
  public classList_set     (value: string,
                            isSet: boolean = true): this { return (isSet) ? this.classList_add(value) : this.classList_remove(value); }
  public hidden            (value: boolean = true): this { this.domEl.hidden      =      value;  return this; }
  public id                (value: string):         this { this.domEl.id          =      value;  return this; }
  public innerHtml         (value: string):         this { this.domEl.innerHTML   =      value;  return this; }
  public scrollTop         (value: number):         this { this.domEl.scrollTop   =      value;  return this; }
  public textContent       (value: string):         this { this.domEl.textContent =      value;  return this; }
//public title             (value: string):         this { this.html.title        =      value;  return this; } -- see tooltip() below
  public textnode          (value: string):         this { this.domEl.appendChild(document.createTextNode(value)); return this; }

  //public get boundingClientRect(): ClientRect { return this.html.getBoundingClientRect(); }
  //public get textOf            (): string     { return this.html.textContent; }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public click       ():                                   this { this.domEl.click();                                              return this; }
  public onblur      (value: (ev: FocusEvent)    => void): this { this.domEl.onblur       = (ev: FocusEvent)    => { value(ev); }; return this; }
  public onchange    (value: (ev: Event)         => void): this { this.domEl.onchange     = (ev: Event)         => { value(ev); }; return this; }
  public onclick     (value: (ev: MouseEvent)    => void): this { this.domEl.onclick      = (ev: MouseEvent)    => { value(ev); }; return this; }
  public ondblclick  (value: (ev: MouseEvent)    => void): this { this.domEl.ondblclick   = (ev: MouseEvent)    => { value(ev); }; return this; }
  public onfocus     (value: (ev: FocusEvent)    => void): this { this.domEl.onfocus      = (ev: FocusEvent)    => { value(ev); }; return this; }
  public onkeypress  (value: (ev: KeyboardEvent) => void): this { this.domEl.onkeypress   = (ev: KeyboardEvent) => { value(ev); }; return this; }
  public onmousedown (value: (ev: MouseEvent)    => void): this { this.domEl.onmousedown  = (ev: MouseEvent)    => { value(ev); }; return this; }
  public onmouseenter(value: (ev: MouseEvent)    => void): this { this.domEl.onmouseenter = (ev: MouseEvent)    => { value(ev); }; return this; }
  public onmouseleave(value: (ev: MouseEvent)    => void): this { this.domEl.onmouseleave = (ev: MouseEvent)    => { value(ev); }; return this; }
  public onmouseout  (value: (ev: MouseEvent)    => void): this { this.domEl.onmouseout   = (ev: MouseEvent)    => { value(ev); }; return this; }
  public onmousemove (value: (ev: MouseEvent)    => void): this { this.domEl.onmousemove  = (ev: MouseEvent)    => { value(ev); }; return this; }
  public onmouseover (value: (ev: MouseEvent)    => void): this { this.domEl.onmouseover  = (ev: MouseEvent)    => { value(ev); }; return this; }
  public onmouseup   (value: (ev: MouseEvent)    => void): this { this.domEl.onmouseup    = (ev: MouseEvent)    => { value(ev); }; return this; }
  public ontouchstart(value: (ev: TouchEvent)    => void): this { this.domEl.ontouchstart = (ev: TouchEvent)    => { value(ev); }; return this; }
  public ontouchend  (value: (ev: TouchEvent)    => void): this { this.domEl.ontouchend   = (ev: TouchEvent)    => { value(ev); }; return this; }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public capturePointer(pointerId: number): this { this.domEl.setPointerCapture    (pointerId); return this; }
  public releasePointer(pointerId: number): this { this.domEl.releasePointerCapture(pointerId); return this; }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public onpointercancel(value: (ev: PointerEvent) => void): this { this.domEl.onpointercancel = (ev: PointerEvent) => { value(ev); }; return this; }
  public onpointerdown  (value: (ev: PointerEvent) => void): this { this.domEl.onpointerdown   = (ev: PointerEvent) => { value(ev); }; return this; }
  public onpointermove  (value: (ev: PointerEvent) => void): this { this.domEl.onpointermove   = (ev: PointerEvent) => { value(ev); }; return this; }
  public onpointerup    (value: (ev: PointerEvent) => void): this { this.domEl.onpointerup     = (ev: PointerEvent) => { value(ev); }; return this; }

  // ---------------------------------------------------------------------------------------------------------------------------------------------------------
  private _pixval(v: number): string { return (v !== 0) ? `${v}px` : '0'; }
  // ---------------------------------------------------------------------------------------------------------------------------------------------------------
  //public backdropFilter (value: string):              this { this.domEl.style.backdropFilter = value; return this; }
  public backgroundColor(value: string):              this { this.domEl.style.backgroundColor = value; return this; }
  public backgroundImage(value: string):              this { this.domEl.style.backgroundImage = value; return this; }
  public background     (value: string):              this { this.domEl.style.background      = value; return this; }
  public border         (value: string):              this { this.domEl.style.border          = value; return this; }
  public borderBottom   (value: string):              this { this.domEl.style.borderBottom    = value; return this; }
  public borderColor    (value: string):              this { this.domEl.style.borderColor     = value; return this; }
  public borderRadius   (value: string):              this { this.domEl.style.borderRadius    = value; return this; }
  public borderRadiusPx (value: number):              this { this.domEl.style.borderRadius    = this._pixval(value); return this; }
  public borderStyle    (value: string):              this { this.domEl.style.borderStyle     = value; return this; }
  public borderTop      (value: string):              this { this.domEl.style.borderTop       = value; return this; }
  public borderWidthPx  (value: number):              this { this.domEl.style.borderWidth     = this._pixval(value); return this; }
  public bottom         (value: string):              this { this.domEl.style.bottom          = value; return this; }
  public bottomPx       (value: number):              this { this.domEl.style.bottom          = this._pixval(value); return this; }
  public boxShadow      (value: string):              this { this.domEl.style.boxShadow       = value; return this; }
  public boxSizing      (value: CssBoxSizing):        this { this.domEl.style.boxSizing       = value; return this; }
  public color          (value: string):              this { this.domEl.style.color           = value; return this; }
  public cursor         (value: string):              this { this.domEl.style.cursor          = value; return this; }
  public display        (value: string):              this { this.domEl.style.display         = value; return this; }
  public filter         (value: string):              this { this.domEl.style.filter          = value; return this; }
  public fontFamily     (value: string):              this { this.domEl.style.fontFamily      = value; return this; }
  public fontSize       (value: string):              this { this.domEl.style.fontSize        = value; return this; }
  public fontSizePx     (value: number):              this { this.domEl.style.fontSize        = this._pixval(value); return this; }
  public fontStyle      (value: CssFontStyle):        this { this.domEl.style.fontStyle       = value; return this; }
  public fontWeight     (value: CssFontWeight):       this { this.domEl.style.fontWeight      = value; return this; }
  public float          (value: string):              this { this.domEl.style.cssFloat        = value; return this; }
  public height         (value: string):              this { this.domEl.style.height          = value; return this; }
  public heightPct      (value: number):              this { this.domEl.style.height          = `${value}%`; return this; }
  public heightPx       (value: number):              this { this.domEl.style.height          = this._pixval(value); return this; }
  public justifyContent (value: string):              this { this.domEl.style.justifyContent  = value; return this; }
  public left           (value: string):              this { this.domEl.style.left            = value; return this; }
  public leftPct        (value: number):              this { this.domEl.style.left            = `${value}%`; return this; }
  public leftPx         (value: number):              this { this.domEl.style.left            = this._pixval(value); return this; }
  public lineHeight     (value: string):              this { this.domEl.style.lineHeight      = value; return this; }
  public lineHeightPx   (value: number):              this { this.domEl.style.lineHeight      = this._pixval(value); return this; }
  public margin         (value: string):              this { this.domEl.style.margin          = value; return this; }
  public marginPx       (value: number):              this { this.domEl.style.margin          = this._pixval(value); return this; }
  public marginBottom   (value: string):              this { this.domEl.style.marginBottom    = value; return this; }
  public marginBottomPx (value: number):              this { this.domEl.style.marginBottom    = this._pixval(value); return this; }
  public marginLeft     (value: string):              this { this.domEl.style.marginLeft      = value; return this; }
  public marginLeftPx   (value: number):              this { this.domEl.style.marginLeft      = this._pixval(value); return this; }
  public marginLeftPct  (value: number):              this { this.domEl.style.marginLeft      = `${value}%`; return this; }
  public marginRight    (value: string):              this { this.domEl.style.marginRight     = value; return this; }
  public marginRightPx  (value: number):              this { this.domEl.style.marginRight     = this._pixval(value); return this; }
  public marginTop      (value: string):              this { this.domEl.style.marginTop       = value; return this; }
  public marginTopPx    (value: number):              this { this.domEl.style.marginTop       = this._pixval(value); return this; }
  public maxHeight      (value: string):              this { this.domEl.style.maxHeight       = value; return this; }
  public maxHeightPx    (value: number):              this { this.domEl.style.maxHeight       = this._pixval(value); return this; }
  public maxWidth       (value: string):              this { this.domEl.style.maxWidth        = value; return this; }
  public maxWidthPx     (value: number):              this { this.domEl.style.maxWidth        = this._pixval(value); return this; }
  public minHeight      (value: string):              this { this.domEl.style.minHeight       = value; return this; }
  public minHeightPct   (value: number):              this { this.domEl.style.minHeight       = `${value}%`; return this; }
  public minWidth       (value: string):              this { this.domEl.style.minWidth        = value; return this; }
  public opacity        (value: string):              this { this.domEl.style.opacity         = value; return this; }
  public opacityF       (value: number):              this { this.domEl.style.opacity         = `${value}`; return this; } // 0..1
  public opacityPct     (value: number):              this { this.domEl.style.opacity         = `${value}%`; return this; } // 0..100
  public outline        (value: string):              this { this.domEl.style.outline         = value; return this; }
  public get overflowHidden():                        this { this.domEl.style.overflow        = 'hidden'; return this; }
  public overflowX      (value: CssOverflowValues):   this { this.domEl.style.overflowX       = value; return this; }
  public overflowY      (value: CssOverflowValues):   this { this.domEl.style.overflowY       = value; return this; }
  public padding        (value: string):              this { this.domEl.style.padding         = value; return this; }
  public paddingPx      (value: number):              this { this.domEl.style.padding         = this._pixval(value); return this; }
  public paddingBottom  (value: string):              this { this.domEl.style.paddingBottom   = value; return this; }
  public paddingBottomPx(value: number):              this { this.domEl.style.paddingBottom   = this._pixval(value); return this; }
  public paddingLeft    (value: string):              this { this.domEl.style.paddingLeft     = value; return this; }
  public paddingLeftPx  (value: number):              this { this.domEl.style.paddingLeft     = this._pixval(value); return this; }
  public paddingRight   (value: string):              this { this.domEl.style.paddingRight    = value; return this; }
  public paddingRightPx (value: number):              this { this.domEl.style.paddingRight    = this._pixval(value); return this; }
  public paddingTop     (value: string):              this { this.domEl.style.paddingTop      = value; return this; }
  public paddingTopPx   (value: number):              this { this.domEl.style.paddingTop      = this._pixval(value); return this; }
  public pointerEvents  (value: string):              this { this.domEl.style.pointerEvents   = value; return this; }
  public position       (value: CssPositionValues):   this { this.domEl.style.position        = value; return this; }
  public get positionAbs():                           this { this.domEl.style.position        = 'absolute'; return this; }
  public get positionRel():                           this { this.domEl.style.position        = 'relative'; return this; }
  public right          (value: string):              this { this.domEl.style.right           = value; return this; }
  public rightPx        (value: number):              this { this.domEl.style.right           = this._pixval(value); return this; }
  public textAlign      (value: CssTextAlignValues):  this { this.domEl.style.textAlign       = value; return this; }
  public textIndentPx   (value: number):              this { this.domEl.style.textIndent      = this._pixval(value); return this; }
  public top            (value: string):              this { this.domEl.style.top             = value; return this; }
  public topPct         (value: number):              this { this.domEl.style.top             = `${value}%`; return this; }
  public topPx          (value: number):              this { this.domEl.style.top             = this._pixval(value); return this; }
  public transform      (value: string):              this { this.domEl.style.transform       = value; return this; }
  public transformOrigin(value: string):              this { this.domEl.style.transformOrigin = value; return this; }
  public userSelect     (value: CssUserSelectValues): this { this.domEl.style.userSelect      = value; return this; }
  public verticalAlign  (value: CssVerticalAlignValues): this { this.domEl.style.verticalAlign= value; return this; }
  public visibility     (value: CssVisibilityValues): this { this.domEl.style.visibility      = value; return this; }
  public width          (value: string):              this { this.domEl.style.width           = value; return this; }
  public widthPct       (value: number):              this { this.domEl.style.width           = `${value}%`; return this; }
  public widthPx        (value: number):              this { this.domEl.style.width           = this._pixval(value); return this; }
  public writingMode    (value: string):              this { this.domEl.style.writingMode     = value; return this; }
  public zIndex         (value: string | number):     this { this.domEl.style.zIndex          = `${value}`; return this; }
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  // DOM helpers
  public get numChildren      ():               number         { return this.domEl.children.length; }
  public get children         ():               HTMLCollection { return this.domEl.children; }
  public     removeChild      (e: HTMLElement): this           { this.domEl.removeChild(e);                           return this; }
  public     removeChildAt    (i: number):      this           { this.domEl.removeChild(this.domEl.children[i]);       return this; }
  public     removeAllChildren():               this           { while (this.numChildren) { this.removeChildAt(0); } return this; }
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
}

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export function any(x: HTMLElement): AnyElement { return new AnyElement(x); }
export class AnyElement extends Element {
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  protected readonly   _x:  HTMLElement;
  public get    domEl  ():  HTMLElement                          { return this._x; }
  public constructor   (x:  HTMLElement)                         { super(); this._x = x; }
  public static of     (x:  HTMLElement): AnyElement             { return new AnyElement(x); }
  public static getById(id: string):      AnyElement | undefined { const el = document.getElementById(id); return (el) ? any(el) : undefined; }
}

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export function audio(x?: HTMLAudioElement): AudioElement { return new AudioElement(x); }
export class AudioElement extends Element {
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  protected readonly _x:  HTMLAudioElement;
  public get   domEl ():  HTMLAudioElement                { return this._x; }
  public constructor (x?: HTMLAudioElement)               { super(); this._x = x || document.createElement('audio'); }
  public static of   (x?: HTMLAudioElement): AudioElement { return new AudioElement(x); }

  public controls(value: boolean = true): this { this._x.controls = value; return this; }
  public pause   ():                      this { this._x.pause();          return this; }
  public play    ():                      this { this._x.play ();          return this; }
  public src     (value: string):         this { this._x.src      = value; return this; }
}
// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export function div(x?: HTMLDivElement): DivElement { return new DivElement(x); }
export class DivElement extends Element {
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  protected readonly   _x:  HTMLDivElement;
  public get      domEl():  HTMLDivElement                          { return this._x; }
  public constructor   (x?: HTMLDivElement)                         { super(); this._x = (x || document.createElement('div')); }
  public static of     (x:  HTMLDivElement): DivElement             { return new DivElement(x); }
  public static getById(id: string):         DivElement | undefined { const el = document.getElementById(id); return (el) ? div(el as HTMLDivElement) : undefined; }
}

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export function img(x?: HTMLImageElement): ImageElement { return new ImageElement(x); }
export class ImageElement extends Element {
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  protected readonly   _x:  HTMLImageElement;
  public get      domEl():  HTMLImageElement                            { return this._x; }
  public constructor   (x?: HTMLImageElement)                           { super(); this._x = (x || document.createElement('img')); }
  public static of     (x:  HTMLImageElement): ImageElement             { return new ImageElement(x); }
  public static getById(id: string):           ImageElement | undefined { const el = document.getElementById(id); return (el) ? img(el as HTMLImageElement) : undefined; }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public src      (value: string): this { this._x.src    = value; return this; }
  public alt      (value: string): this { this._x.alt    = value; return this; }
  public imgHeight(value: number): this { this._x.height = value; return this; }
  public imgWidth (value: number): this { this._x.width  = value; return this; }
}

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export function input(x?: HTMLInputElement): InputElement { return new InputElement(x); }
export class InputElement extends Element {
  protected readonly _x: HTMLInputElement;

  public get domEl(): HTMLInputElement { return this._x; }

  public constructor(x?: HTMLInputElement) {
    super();
    this._x = (x || document.createElement('input'));
  }

  public static of(x?: HTMLInputElement): InputElement {
    return new InputElement(x);
  }

  public autocomplete(value: string): this { this._x.autocomplete = value; return this; }
  public blur        ():              this { this._x.blur();               return this; }
  public disabled    (value: boolean):this { this._x.disabled     = value; return this; }
  public enterKeyHint(value: InputEnterKeyHintValue): this { this._x.enterKeyHint = value; return this; }
  public focus       ():              this { this._x.focus();              return this; }
  public inputMode   (value: string): this { this._x.inputMode    = value; return this; }
  public name        (value: string): this { this._x.name         = value; return this; }
  public placeholder (value: string): this { this._x.placeholder  = value; return this; }
  public type        (value: string): this { this._x.type         = value; return this; }
  public value       (value: string): this { this._x.value        = value; return this; }

  public onFocus     (value: (ev: FocusEvent) => void): this { this.domEl.onfocus = (ev: FocusEvent) => { value(ev); }; return this; }
  public onInput     (value: (ev: Event)      => void): this { this.domEl.oninput = (ev: Event)      => { value(ev); }; return this; }

  public getValue(): string { return this._x.value; }
}

