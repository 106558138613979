// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export type SongInfo = {
  year: number,
  title: string,
  filename: string,
  isWip?: boolean,
  search: string,
};

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export class SongManifest {
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  private readonly infos: SongInfo[] = [
    { year: 2018, title: `Check'r Your Six`,          filename: `Check'rYourSix.200328.mp3`,        search: `checkr-your-six` },
    { year: 2018, title: `Jawbreaker`,                filename: `Jawbreaker.200327.mp3`,            search: `jawbreaker` },
    { year: 2018, title: `Ordinary Way`,              filename: `OrdinaryWay.200325.mp3`,           search: `ordinary-way` },
    { year: 2018, title: `The Reveal`,                filename: `TheReveal.200324.mp3`,             search: `the-reveal` },
    { year: 2019, title: `I Just Remember Your Face`, filename: `IJustRememberYourFace.200323.mp3`, search: `i-just-remember-your-face` },
    { year: 2019, title: `Easter Beagle Birdland`,    filename: `EasterBeagleBirdland.200322.mp3`,  search: `easter-beagle-birdland` },
    { year: 2019, title: `It's Lickatung!`,           filename: `ItsLickatung.200321.mp3`,          search: `its-lickatung` },
    { year: 2020, title: `Limelight (Rush)`,          filename: `Limelight.200725.mp3`,             search: `limelight` },
    { year: 2020, title: `Pandemic Song`,             filename: `PandemicSong.200413.mp3`,          search: `pandemic-song` },
    { year: 2020, title: `People Say`,                filename: `PeopleSay.200501.mp3`,             search: `people-say` },
    { year: 2020, title: `True, True, True`,          filename: `TrueTrueTrue.200531.mp3`,          search: `true-true-true` },
    { year: 2020, title: `Faster Than Your Feet`,     filename: `FasterThanYourFeet.200719.mp3`,    search: `faster-than-your-feet` },
    { year: 2020, title: `Fiasco!`,                   filename: `Fiasco!.200922.mp3`,               search: `fiasco` },
    { year: 2020, title: `X Digits`,                  filename: `XDigits.201125.mp3`, isWip: true,  search: `x-digits` },
    { year: 2021, title: `One of These Days`,         filename: `OneOfTheseDays.210323.mp3`,        search: `one-of-these-days` },
    { year: 2021, title: `Prince Rupert Awakes`,      filename: `PrinceRupertAwakes.210817.mp3`,    search: 'prince-rupert-awakes' },
    { year: 2022, title: `Tagalong`,                  filename: `Tagalong.220226.mp3`,              search: 'tagalong' },
  ]
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  // NOTE direct link in url, eg. https://victoryhearts.com/?song=tagalong
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public get years(): number[] {
    const x: number[] = [];
    for (const i of this.infos) { if (x.indexOf(i.year) < 0) { x.push(i.year); } }
    x.sort();
    return x;
  }
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public songs(year: number): SongInfo[] {
    const x: SongInfo[] = [];
    if (year !== 0) {
      for (const i of this.infos) {
        if (i.year === year) { x.push(i); }
      }
    }
    else {
      // reverse chrono by year, but forward within year
      const allYearsHighToLow = this.years.reverse();
      for (const yearH2L of allYearsHighToLow) {
        for (const i of this.infos) {
          if (i.year === yearH2L) { x.push(i); }
        }
      }
    }
    return x;
  }
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public getViaSearch(search: string): SongInfo | undefined {
    for (const i of this.infos) {
      if (i.search === search) { return i; }
    }
    return undefined;
  }
}
