// -------------------------------------------------------------------------------------------------------------------------------------------------------------
import dayjs from 'dayjs'; // ref: https://day.js.org/docs/en/installation/installation

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export function now():                              number { return (new Date()).getTime(); }
export function ago(tStart: number, tEnd?: number): number { return (tEnd || now()) - tStart; }

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export function formatTimestamp(posixMsec: number): string { //}, format?: string): string {

  const m        = dayjs(posixMsec);
  const posixNow = now();
  const absDt    = Math.abs(posixNow - posixMsec);
  let s: string;

  if      (dayjs(posixNow).format('YYYYMMDD') === m.format('YYYYMMDD')) { s = m.format('h:mm a') }      // from today
  else if (absDt <      (7 * 24 * 60 * 60 * 1000))                      { s = m.format('ddd h:mm a'); } // within the last week?
  else if (absDt < (26 * 7 * 24 * 60 * 60 * 1000))                      { s = m.format('M/D h:mm a'); } // older
  else                                                                  { s = m.format('M/D/YYYY'); }   // really old

  return s;
}

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export function formatTimestampToParts(posixMsec: number): { pre: string, time: string, ampm: string } {

  const m        = dayjs(posixMsec);
  const posixNow = now();
  const absDt    = Math.abs(posixNow - posixMsec);

  let v = { pre: m.format('M/D'), time: m.format('h:mm'), ampm: m.format('a') };

  if      (dayjs(posixNow).format('YYYYMMDD') === m.format('YYYYMMDD')) { v.pre = ''; } //'today'; }      // from today
  else if (absDt <      (7 * 24 * 60 * 60 * 1000))                      { v.pre = m.format('ddd'); } // within the last week?
  else if (absDt < (26 * 7 * 24 * 60 * 60 * 1000))                      { } // older - default
  else                                                                  { v = { pre: '', time: m.format('M/D/YYYY'), ampm: '' }; } // really old

  return v;
}

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export function formatDuration(msec: number, opts?: { min?:'sec'|'min'|'hrs', max?: 'hrs'}): string {
  msec = Math.trunc(msec);
  if (msec <= 0)   { return '-'; }
  if (msec <= 999) { return '0'; }

  let sec = Math.trunc(msec / 1000);
  if (sec < 60) {
    if (opts && opts.min && (opts.min !== 'sec')) return '0m';
    return `${sec}s`;
  }

  let min = Math.trunc(sec / 60);
  sec = sec % 60;
  if (min < 60) {
    if (opts && opts.min && (opts.min !== 'min')) return `${min}m`;
    if (opts && opts.min && (opts.min !== 'hrs')) return `0h`;
    return sec ? `${min}m ${sec}s` : `${min}m`;
  }

  let hrs = Math.trunc(min / 60);
  min = min % 60;
  if ((hrs < 24) || (opts && opts.max && (opts.max === 'hrs'))) {
    if (opts && opts.min && (opts.min === 'hrs')) return `${hrs}h`;
    return min ? `${hrs}h ${min}m` : `${hrs}h`;
  }

  let day = Math.trunc(hrs / 24);
  hrs = hrs % 24;
  if (day < 7) {
    return hrs ? `${day}d ${hrs}h` : `${day}d`;
  }
  return `${day}d`;

  //let wks = Math.trunc(day / 7);
  //day = day % 7;
  //if (wks < 52) {
  //  return day ? `${wks}w ${day}d` : `${wks}w`;
  //}

  //let yrs = Math.trunc(wks / 52);
  //wks = wks % 52;
  //return wks ? `${yrs}y ${wks}w` : `${yrs}y`;
}
