// -------------------------------------------------------------------------------------------------------------------------------------------------------------
import dayjs from 'dayjs'; // ref: https://day.js.org/docs/en/installation/installation

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
import    { AppBuildInfo }         from './app-build-info';
import    { AppDarkMode }          from './app-dark-mode';
import    { AppNative }            from './app-native';
import * as Html                   from '../lib/html';
import    { Player }               from '../player/player';
import    { updateHtmlAnimations } from '../lib/animate-html';

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export class App {
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  // these all have no-op constructor() plus async initialize()
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public readonly buildInfo:          AppBuildInfo;
  public readonly darkMode:           AppDarkMode;
  public readonly native:             AppNative;

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public readonly el:                 Html.DivElement;
  public readonly player:             Player;

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  constructor() {
    this.buildInfo        = new AppBuildInfo();
    this.darkMode         = new AppDarkMode();
    this.native           = new AppNative();
    this.el               = Html.div();
    this.player           = new Player();

    window.onload = async () => {
      const t0: number = (new Date()).getTime();

      await this.darkMode        .initialize();
      await this.native          .initialize();
  
      console.log(`Hello from app, ${dayjs().format('YYYY-MM-DD, h:mm a')}`);
    
      //document.body.classList.add('dark');
  
      const div = this.el;
      //div.paddingPx(10).backgroundColor('#722');
      div.positionAbs.widthPct(100).heightPct(100).className('bgc-app');
      //div.onclick(() => { app.darkMode.cycleToNextPreference(); }); // just for testing:

      document.body.appendChild(div.domEl);

      div.append(this.player.el);
  
      window.addEventListener('resize', () => { this.onResize(); }, { passive: true });
      this.onResize();
  
      console.log(`elapsed app start time = ${((new Date()).getTime() - t0)} msec`);
  
      requestAnimationFrame(() => { this.onAnimationFrame()} );

      const searchParams = new URLSearchParams(window.location.search);
      const keyword = 'song';
      if (searchParams.has(keyword)) {
        const value = searchParams.get(keyword) || '';
        console.log(`query ${keyword} is: "${value}"`);
        this.player.selectSongViaSearch(value);
      }
    };
  }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  private onAnimationFrame() {
    updateHtmlAnimations();

    this.player.animate();

    requestAnimationFrame(() => {this.onAnimationFrame()});
  }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  private clientW: number = 0;
  private clientH: number = 0;
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  private onResize() {
    const w = window.innerWidth;
    const h = window.innerHeight;

    if (!w || !h) return;

    if ((w !== this.clientW) || (h !== this.clientH)) {
      this.clientW = w;
      this.clientH = h;

      //this.el.widthPx(w).heightPx(h);

      this.player.resize();
      //this.spriteman.resize({at:{x:4,y:4},sz:{w:size.w-8,h:size.h-8}});
    }
  }
}

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export const app: App = new App();
