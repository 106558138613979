import * as Html from './html';

type InProgress = {
  el:     Html.Element,
  fxn:    (f: number, el: Html.Element) => void,
  f:      number,
  t:      number,
  dfDt:   number,
  isDone: boolean,
};

const inProgresses: InProgress[] = [];

function findInProgress(el: Html.Element): { index: number, ip?: InProgress } {
  for (let i: number = 0; i < inProgresses.length; i++) {
    if (inProgresses[i].el === el) return { index: i, ip: inProgresses[i]};
  }
  return { index: -1 };
}

export function updateHtmlAnimations() {
  const time = (new Date()).getTime();
  let index = 0;
  while (index < inProgresses.length) {
    if (update(inProgresses[index], time, index) === false) { }
    else { index++; }
  }
}

function update(ip: InProgress, time?: number, index?: number): boolean {
  time = time || (new Date()).getTime();
  const dt = time - ip.t;
  const df = dt * ip.dfDt;
  const nf = (Math.max(0, Math.min(1, ip.f + df)));
  //console.log(`update(): f ${ip.f}, dt ${dt}, dfDt ${ip.dfDt} -> df ${df} -> f ${nf}`)
  ip.f = nf;
  ip.t = time;
  ip.fxn(ip.f, ip.el);
  if (((ip.f === 0) && (ip.dfDt < 0)) || ((ip.f === 1) && (ip.dfDt > 0))) {
    if (index === undefined) { index = findInProgress(ip.el).index; }
    inProgresses.splice(index, 1);
    return false; // <- has ended
  }
  return true; // <- still going
}

// if dt > 0, f goes 0->1 in that time, else f goes 1->0 in abs(dt)
// but if an animate on that el is already in progress, this takes over from its current state
export function animateHtml(el: Html.Element, dt: number, fxn: (f: number, el: Html.Element) => void) {
  if (dt === 0) dt = 1000;
  const time = (new Date()).getTime();
  const item = findInProgress(el);
  if (item.ip) {
    item.ip.fxn  = fxn;
    item.ip.dfDt = 1 / dt;
  }
  else {
    item.index = inProgresses.push(item.ip = { el: el, fxn: fxn, f: (dt > 0) ? 0: 1, t: time, dfDt: 1 / dt, isDone: false }) - 1;
  }

  update(item.ip, time, item.index);
}
