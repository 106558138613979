// -------------------------------------------------------------------------------------------------------------------------------------------------------------
import { Capacitor, DeviceBatteryInfo, DeviceInfo, KeyboardInfo, KeyboardStyle, Plugins } from '@capacitor/core';
//import { ScreenOrientation } from '@ionic-native/screen-orientation'; // <- this DOES work on native!
// -------------------------------------------------------------------------------------------------------------------------------------------------------------
import    { app }      from './app';
//import    { ipc }      from '../lib/ipc';

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export type IosType = 'iPad' | 'iPhone' | 'iPod' | 'none';

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export class AppNative {
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public readonly ipcName: string = 'app-native';
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  private info?:        DeviceInfo        = undefined;
  private batteryInfo?: DeviceBatteryInfo = undefined;

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  // these flags indicate if latinum is running as a native app on an apple device
  private   _iosType:            IosType = 'none';
  public get iosType():          IosType { return  this._iosType; }
  public get is_iPad():          boolean { return (this.iosType === 'iPad'); }
  public get is_iPhone():        boolean { return (this.iosType === 'iPhone'); }
  public get is_iPod():          boolean { return (this.iosType === 'iPod'); }
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  // these flags indicate if latinum is running in a browser app on an apple device
  private   _iosBrowserType:     IosType = 'none';
  public get iosBrowserType():   IosType { return  this._iosBrowserType; }
  public get is_BrowseriPad():   boolean { return (this._iosBrowserType === 'iPad'); }
  public get is_BrowseriPhone(): boolean { return (this._iosBrowserType === 'iPhone'); }
  public get is_BrowseriPod():   boolean { return (this._iosBrowserType === 'iPod'); }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public async initialize() {

    // this plugin is well-supported and we don't really expect it to ever fail
    console.log('app-device checking device info...')
    if (!Capacitor.isPluginAvailable('Device')) {
      console.warn(`no support for capacitor device plugin`);
    }
    else {
      this.info        = await Plugins.Device.getInfo();
      this.batteryInfo = await Plugins.Device.getBatteryInfo();

      // in android, appBuild gets set to the stringified versionCode from build.gradle, eg. "10512"
      //             appVersion gets set to versionName from build.grade,                eg. "1.5.12"
      if (this.isNativeAndroid) {
        this.info.appBuild   = this.info.appVersion; // <- the three digit version
        this.info.appVersion = this.info.appVersion.substr(0, this.info.appVersion.lastIndexOf('.'));
      }
      else {
        // appBuild (#.#.#) and appVersion (#.#) are reported on native IOS hardware; injest here for any missing platform (eg., browser)
        if (!this.info.appBuild)   this.info.appBuild   = `${app.buildInfo.versionString}.${app.buildInfo.buildNumber}`;
        if (!this.info.appVersion) this.info.appVersion =    app.buildInfo.versionString;
      }

      //console.log(`device info: ${JSON.stringify(this.info, undefined, ' ')}`);
      console.log(`device battery info: ${JSON.stringify(this.batteryInfo)}`); // , undefined, ' ')}`);

      if (this.isNativeIos) {
        const lm = this.info.model.toLowerCase();
        if      (lm.indexOf('ipad')   >= 0) { this._iosType = 'iPad'; }
        else if (lm.indexOf('iphone') >= 0) { this._iosType = 'iPhone'; }
        else if (lm.indexOf('ipod')   >= 0) { this._iosType = 'iPod'; }
      }

      //console.log(`navigator platform = ${navigator.platform}`);
      const ua = navigator.userAgent;
      if      (ua.indexOf('iPad')   >= 0) { this._iosBrowserType = 'iPad'; }
      else if (ua.indexOf('iPhone') >= 0) { this._iosBrowserType = 'iPhone'; }
      else if (ua.indexOf('iPod')   >= 0) { this._iosBrowserType = 'iPod'; }

      // see: https://stackoverflow.com/questions/58019463/how-to-detect-device-name-in-safari-on-ios-13-while-it-doesnt-show-the-correct
      // detect iPad IOS 13+ in Safari, which lies and pretends to be a desktop browser
      if ((navigator.platform === 'MacIntel') && (navigator.maxTouchPoints > 1) && !window.MSStream) { this._iosBrowserType = 'iPad'; }
    }

    /*
    // TODO: will need to redo this with a native capacitor solution!
    // ref: https://medium.com/@hinddeep.purohit007/handling-screen-orientation-changes-in-capacitor-apps-19fe339578a6
    // try to lock phones and ipods into portrait mode, ipads to landscape
    if (this.isNativeIos) {
      const lockTarget = this.is_iPad ? ScreenOrientation.ORIENTATIONS.LANDSCAPE : ScreenOrientation.ORIENTATIONS.PORTRAIT;
      try {
        ScreenOrientation.lock(lockTarget).then(
          () => { console.log(`screen orientation lock via plugin, resolved`); },
          (rejectReason: any) => { console.warn(`screen orientation lock via plugin, rejected: ${rejectReason}`); }
        );
      }
      catch(e) { console.warn(`screen orientation lock ex: ${e}`); }
    }
    */

    // native keyboard support
    if (!Capacitor.isPluginAvailable('Keyboard')) {
      if (this.isNative) {
        // this would be surprising...
        console.warn(`no support for capacitor keyboard plugin on native platform!`);
      }
    }
    else {
      // this app has no keyboard input!
      /*
      ipc.listen(this, 'dark-mode-changed', (data?: any) => {
        const isSet: boolean = data as boolean;
        console.log(`on dark mode changed (is set? ${isSet}), set kb style`);
        Plugins.Keyboard.setStyle({style: isSet ? KeyboardStyle.Dark : KeyboardStyle.Light });
      });

      Plugins.Keyboard.addListener('keyboardWillShow', (info: KeyboardInfo) => {
        console.log(`keyboard will show with height ${info.keyboardHeight}, is dark = ${app.darkMode.isSet}`);
        Plugins.Keyboard.setStyle({style: app.darkMode.isSet ? KeyboardStyle.Dark : KeyboardStyle.Light });
        ipc.signal('native-kb-will-show', info.keyboardHeight);
      });
      
      Plugins.Keyboard.addListener('keyboardDidShow', (info: KeyboardInfo) => {
        //console.log('keyboard did show with height', info.keyboardHeight);
        ipc.signal('native-kb-did-show', info.keyboardHeight);
      });
      
      Plugins.Keyboard.addListener('keyboardWillHide', () => {
        //console.log('keyboard will hide');
        ipc.signal('native-kb-will-hide');
      });
      
      Plugins.Keyboard.addListener('keyboardDidHide', () => {
        //console.log('keyboard did hide');
        ipc.signal('native-kb-did-hide');
      });

      // you can achieve the same with window events but they are 100% redundant...
      //window.addEventListener('keyboardWillShow', (e) => {
      //  console.log('w! keyboard will show with height', (<any>e).keyboardHeight);
      //});
      */
    }
  }

  // note only iphones 8+ have notch
  // the older ones have max screen h 736,
  // the new ones, with the notch, are over 800

  /*
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  private   _iosDeviceInfo:   IosDeviceInfo = undefined;
  public get iosDeviceInfo(): IosDeviceInfo { return this._iosDeviceInfo; }
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  private _portraitScreenSize: string = '';
  //private _isPortraitModeNow: boolean = true;
  public onNewScreenSize(w: number, h: number) {
    //this._isPortraitModeNow = h > w;
    const pss: string = `${Math.min(h, w)}x${Math.max(h,w)}`;
    if (pss === this._portraitScreenSize) return;

    // see: https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/adaptivity-and-layout/
    switch (pss) {
      // iPads:
      case '1024x1366': this._iosDeviceInfo = { model: 'iPad', screenSize: pss, hasNotch: false }; break; // 12.9" Pro
      case  '834x1194': this._iosDeviceInfo = { model: 'iPad', screenSize: pss, hasNotch: false }; break; // 11" or 10.5" Pro
      case  '768x1024': this._iosDeviceInfo = { model: 'iPad', screenSize: pss, hasNotch: false }; break; // 9.7" Pro/Air/- or 7.9" mini
      case  '834x1112': this._iosDeviceInfo = { model: 'iPad', screenSize: pss, hasNotch: false }; break; // 10.5" Air
      case  '810x1080': this._iosDeviceInfo = { model: 'iPad', screenSize: pss, hasNotch: false }; break; // 10.2" -

      // iPhones:
      case   '414x896': this._iosDeviceInfo = { model: 'iPhone', iPhoneVersion:'11', screenSize: pss, hasNotch: false }; break; // also 11 Pro Max
      case   '375x812': this._iosDeviceInfo = { model: 'iPhone', iPhoneVersion:'11 Pro', screenSize: pss, hasNotch: false }; break;
      case   '375x812': this._iosDeviceInfo = { model: 'iPhone', iPhoneVersion:'11 Pro', screenSize: pss, hasNotch: false }; break;
    }
  }
  */

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public get uuid():            string  { return this.info ? this.info.uuid : ''; }
  public get isNative():        boolean { return this.info ? ((this.info.platform === 'ios') || (this.info.platform === 'android')) : false; }
  public get isNativeIos():     boolean { return this.info ? (this.info.platform === 'ios') : false; }
  public get isBrowserIos():    boolean { return this.iosBrowserType !== 'none'; }
  public get isNativeAndroid(): boolean { return this.info ? (this.info.platform === 'android') : false; }
}

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
/*

deviceInfo: EXAMPLES

attribute name    Chrome on Windows                         Chrome on MacBook                         Native ipod touch
--------------    --------------------------------------    --------------------------------------    --------------------------------------
appBuild          ""                                        ""                                        "1.3.16"
appId             ""                                        ""                                        "com.carmalink.app.latinum"
appName           ""                                        ""                                        "Carmalink"
appVersion        ""                                        ""                                        "1.3"
diskFree          N/A                                       N/A                                       17009201152
diskTotal         N/A                                       N/A                                       31989469184
isVirtual         false                                     false                                     false
memUsed           N/A                                       N/A                                       107053056
manufacturer      "Google Inc."                             "Google Inc."                             "Apple"
model             "Windows NT 10.0"                         "Macintosh"                               "iPod touch"
name              N/A                                       N/A                                       "Martin’s iPod touch"
operatingSystem   "windows"                                 "mac"                                     "ios"
osVersion         "Windows NT 10.0; Win64; x64"             "10.15.6"                                 "13.5.1"
platform          "web"                                     "web"                                     "ios"
uuid              "f56a7a5d-610b-4d02-acb0-a4c3f686310c"    "4587e31b-84fe-4a2d-96b4-be1438bf0e3f"    "9F0A4E1E-F8A5-4ADF-911E-EAE104E5B02C"

*/
