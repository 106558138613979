// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export type DarkModePreference = 'system' | 'light' | 'dark';

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
// the goal for dark mode for this app is to set it correctly from system, and allow user to force is to opposite
export class AppDarkMode {
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public set isFlippedByUser(value: boolean) {
    this._isFlippedByUser = value;
    this._update();
  }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  private   _isSetAtSystemLevel: boolean = false;
  private   _isFlippedByUser:    boolean = false;
  public get isSet():            boolean { return this._isFlippedByUser ? !this._isSetAtSystemLevel : this._isSetAtSystemLevel; }
  public get value():   'dark' | 'light' { return this.isSet ? 'dark' : 'light'; }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  private _update() {
    if (this.isSet) { document.body.classList.add   ('dark'); }
    else            { document.body.classList.remove('dark'); }
  }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  // see: https://stackoverflow.com/questions/50840168/how-to-detect-if-the-os-is-in-dark-mode-in-browsers
  public async initialize() {
    // now, and later...
    //ipc.listen(this, 'settings-loaded', () => {
    //  xonsole.log(`dark-mode: updating with user settings preference`);
    //  this._applyUserPreference(app.settings.darkModePreference);
    //});
    //ipc.listen(this, 'settings-appearance-dark-mode-preference', (data: { value: DarkModePreference }) => {
    //  this._applyUserPreference(data.value);
    //});

    let step:   string = '';
    let report: string = '';
    try {
      step = 'window?';
      if (!window) { report = 'no'; }

      if (!report) {
        step = 'window.matchMedia?';
        if (!window.matchMedia) { report = 'no'; }
      }

      let windowMatchMedia: MediaQueryList | undefined = undefined;
      if (!report) {
        step = `window.matchMedia('(prefers-color-scheme: dark)')?`;
        windowMatchMedia = window.matchMedia('(prefers-color-scheme: dark)');
        if (!windowMatchMedia) { report = 'no; dark mode is not supported here'; }
      }

      if (windowMatchMedia) {
        this._isSetAtSystemLevel = (windowMatchMedia.matches === true);
        this._update();

        step = 'addEventListener?'
        if (windowMatchMedia.addEventListener) {
          report = 'yes, using this for dynamic change detection';
          windowMatchMedia.addEventListener('change', e => {
            this._isSetAtSystemLevel = (e.matches === true);
            this._update();
          });
        }
        else {
          step = 'addListener?';
          if (windowMatchMedia.addListener) {
            report = 'yes, using this for dynamic change detection';
            windowMatchMedia.addListener(e => {
              this._isSetAtSystemLevel = (e.matches === true);
              this._update();
            });
          }
          else {
            step = 'attachEvent?';
            if ((windowMatchMedia as any).attachEvent) {
              report = 'yes, using this for dynamic change detection';
              (windowMatchMedia as any).attachEvent('change', (e: any)  => {
                console.log(`note, typeof e in attachEvent is ${typeof e}`);
                this._isSetAtSystemLevel = (window.matchMedia('(prefers-color-scheme: dark)').matches === true);
                this._update();
              });
            }
            else {
              step = 'out of event options';
              report = 'no dynamic support for changing dark mode';
            }
          }
        }
      }
    }
    catch (e) {
      report = `ex: ${e}`;
    }

    console.log(`setupDarkMode(): ${step} -> ${report}`);
  }
}
