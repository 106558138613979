// -------------------------------------------------------------------------------------------------------------------------------------------------------------
import    { app }                    from '../app/app';
import * as Dttm                     from '../lib/dttm';
import * as Html                     from '../lib/html';
import    { SongInfo, SongManifest } from './song-manifest';

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export type ButtonState = 'disabled' | 'enabled' | 'armed' | 'active';
// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export class Button {
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public  readonly el: Html.DivElement;
  private readonly inner: Html.DivElement;

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  //private readonly fontFamily: string = `VT323`;
  //private readonly fontFamily: string = `Arcade Normal`;
  private readonly fontFamily: string = `Acumin Pro Bold`;
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public constructor() {
    this.el    = Html.div().positionAbs;
    this.inner = Html.div().positionAbs.fontFamily(this.fontFamily).userSelect('none');

    this.el.append(this.inner);
    this.state('enabled');
  }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  private   _value:           any        = undefined;
  public     value(x:         any): this { this._value = x; return this; }
  public get valueAsAny():    any        {                  return this._value; }
  public get valueAsNumber(): number     {                  return this._value as number; }
  public get valueAsString(): string     {                  return this._value as string; }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public html  (s: string): this { this.inner.innerHtml  (s); return this; }
  public text  (s: string): this { this.inner.textContent(s); return this; }
  public center():          this { this.inner.textAlign('center'); this.isCentered = true; return this; }
  private isCentered = false;

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  private _state: ButtonState = 'enabled';
  public   state(value: ButtonState): this {
    this._state = value;
    if      (value === 'active')   { this.el.backgroundColor('#d8ffd8'); this.inner.backgroundColor('#e0f0e0').color('#000'); }
    else if (value === 'enabled')  { this.el.backgroundColor('#d8d8ff'); this.inner.backgroundColor('#e0e0f0').color('#222'); }
    else if (value === 'disabled') { this.el.backgroundColor('#b8b8df'); this.inner.backgroundColor('#c0c0d0').color('#444'); }
    return this;
  }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public animate (isFlashOn: boolean) {
    if (this._state === 'armed') {
      if (isFlashOn) { this.el.backgroundColor('#dfdfa8'); this.inner.backgroundColor('#d4d4a4').color('#000'); }
      else           { this.el.backgroundColor('#dcdcd4'); this.inner.backgroundColor('#dadacf').color('#222'); } // <- enabled is the off state
  
    }
  }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public size(w: number, h: number): this {
    this.el.widthPx(w).heightPx(h);
    const f = h * 0.1;
    this.inner.leftPx(f).widthPx(w - f - f).topPx(f).heightPx(h - f - f).borderRadiusPx(f).fontSizePx(h * 0.65).lineHeightPx(h * 0.85);
    if (!this.isCentered) this.inner.textIndentPx(f+f)
    return this;
  }
}

// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export class Player {
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public  readonly el:     Html.DivElement;
  private readonly mainEl: Html.DivElement;
  private readonly audio:  Html.AudioElement;

  private readonly grilles: Html.DivElement[];
  private readonly darkModeButton: Button;

  private readonly allSongsButton: Button;
  private readonly prevYearButton: Button;
  private readonly yearButton: Button;
  private readonly nextYearButton: Button;

  private readonly songButtons: Button[];
  private readonly pageButton: Button;
  private readonly vhLogoImg: Html.ImageElement;
  private readonly vhTextDiv: Html.DivElement;

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  private readonly time0:               number;
  private readonly songs:               SongManifest;
  private          year:                number   = 0; // <- 0 === all
  private          page:                number   = 0;
  private          maxPages:            number   = 1;
  private          numSongButtons:      number   = 0;
  private          selectedSong?:       SongInfo = undefined;
  private          selectedSongButton?: Button   = undefined;
  private          isSearchSongFirstPlay: boolean = false;

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  private readonly inset: number = 20;

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public constructor() {
    this.time0 = Dttm.now();
    this.songs = new SongManifest();
    //console.log(`songs years = ${JSON.stringify(this.songs.years)}`); // <- test

    //console.log(`songs years = ${JSON.stringify(this.songs.years)}`); // <- test
    //for (const si of this.songs.songs(2020)) { console.log(`- ${si.year}: ${si.title}`); } // <- test

    this.el = Html.div();
    this.el.positionAbs.className('bgc-player-side shadow-1');

    this.grilles = [];
    for (let i = 0; i < 7; i++) {
      const grille = Html.div().positionAbs.backgroundColor(`#665`);
      this.el.append(grille);
      this.grilles.push(grille);
    }

    this.el.append((this.darkModeButton = new Button()).value(0).el);
    this.darkModeButton.el.onclick(() => {
      if (this.darkModeButton.valueAsNumber === 0) {
        this.darkModeButton.value(1).state('active');
        app.darkMode.isFlippedByUser = true;
      }
      else {
        this.darkModeButton.value(0).state('enabled');
        app.darkMode.isFlippedByUser = false;
      }
    });

    this.mainEl = Html.div().positionAbs.className('bgc-player-body shadow-2');
    this.el.append(this.mainEl);

    this.audio = Html.audio().positionAbs.controls();//.userSelect('none');
    this.audio.domEl.onpause = () => { if (this.selectedSongButton) { this.selectedSongButton.state('armed');  this.resize(); }};
    this.audio.domEl.onplay  = () => { if (this.selectedSongButton) { this.selectedSongButton.state('active'); this.resize(); }};
    //this.audio.filter(`sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%)`);
    this.mainEl.append(this.audio);

    this.mainEl.append((this.allSongsButton = new Button().center().text('All Songs')).el);
    this.mainEl.append((this.prevYearButton = new Button().center().html('&#9664;'))  .el);
    this.mainEl.append((this.yearButton     = new Button().center().text('?'))        .el);
    this.mainEl.append((this.nextYearButton = new Button().center().html('&#9654;'))  .el);

    const mostRecentYear = this.songs.songs(0)[0].year;
    this.yearButton.text(mostRecentYear.toString()).value(mostRecentYear);

    this.allSongsButton.el.onclick(() => { if (this.year !== 0) { this.year = 0; this.resize(); } });
    this.yearButton    .el.onclick(() => { if (this.year !== this.yearButton.valueAsNumber) { this.year = this.yearButton.valueAsNumber; this.resize(); } });
    this.prevYearButton.el.onclick(() => {
      let year = this.yearButton.valueAsNumber - 1;
      if (!this.songs.songs(year).length) { year = this.songs.songs(0)[0].year; }
      this.yearButton.text(year.toString()).value(year);
      this.year = year; this.resize();
    });
    this.nextYearButton.el.onclick(() => {
      let year = this.yearButton.valueAsNumber + 1;
      if (!this.songs.songs(year).length) { year = this.songs.years[0]; }
      this.yearButton.text(year.toString()).value(year);
      this.year = year; this.resize();
    });

    this.songButtons = [];
    for (let i = 0; i < 50; i++) {
      const songButton = new Button();
      this.mainEl.append(songButton.el);
      this.songButtons.push(songButton);
      songButton.el.onclick(() => {
        const song = songButton.valueAsAny as SongInfo;
        if (song) {
          if (this.selectedSong !== song) {
            this.selectedSong       = song;
            this.selectedSongButton = songButton;
            console.log(`selected: "${this.selectedSong.title}"`);

            this.audio.pause().src(`mp3/${song.year}/${song.filename}`);
            // when arriving with query search song, not allow3ed to auto play it!
            if (!this.isSearchSongFirstPlay) {
              this.audio.play();

              if (window.location.search) {
                const fullUrl = window.location.toString();
                const lessUrl = fullUrl.substr(0, fullUrl.indexOf(window.location.search));
                //console.log(`full url is: ${fullUrl}`);
                //console.log(`less url is: ${lessUrl}`);
                window.history.replaceState({}, document.title, `${lessUrl}`);
              }
            }
            else {
              this.isSearchSongFirstPlay = false;
            }

            //selectDiv.fontSizePx(15).innerHtml(`Now playing: <b>${songs[index].title}</b>`)
            this.resize();
          }
          else if (this.audio.domEl.paused) {
            songButton.state('active');
            this.audio.play();
          }
          else {
            songButton.state('armed');
            this.audio.pause();
          }
        }
      });
    }

    this.vhLogoImg = Html.img().src(`img/vh-logo-noglow.200x150.png`).positionAbs.imgWidth(100);
    this.mainEl.append(this.vhLogoImg);

    this.vhTextDiv = Html.div().fontFamily(`Acumin Pro Bold Italic`).textContent(`Victory Hearts Music Player App`).positionAbs.widthPx(150);
    this.mainEl.append(this.vhTextDiv);

    this.pageButton = new Button().state('disabled').html('page &#9654;').center();
    this.pageButton.el.onclick(() => {
      if (this.maxPages > 1) {
        this.resize((this.page + 1) % this.maxPages);
      }
    });
    this.mainEl.append(this.pageButton.el);
  }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public selectSongViaSearch(search: string) {
    const song = this.songs.getViaSearch(search);
    if (song) {
      while (this.year !== song.year) { this.nextYearButton.el.click(); }
      for (const songButton of this.songButtons) {
        if ((songButton.valueAsAny as SongInfo) === song) {
          this.isSearchSongFirstPlay = true;
          songButton.el.click();
          break;
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public resize(page: number = 0) {
    const clientW = window.innerWidth;
    const clientH = window.innerHeight;

    this.page           = page;
    this.numSongButtons = 0;

    // portrait mode (narrow)
    const bodyW = Math.max(335, Math.min(clientW - (2 * this.inset), 512));
    const bodyH = Math.min(clientH - (2 * this.inset), bodyW * 1.75);
    const bodyX = (clientW - bodyW) / 2;
    const bodyY = (clientH - bodyH) / 2;
    this.el.leftPx(bodyX).topPx(bodyY).widthPx(bodyW).heightPx(bodyH);

    const scale = bodyW / 512;
    const s = (n: number): number => { return n * scale};

    this.el.borderRadiusPx(s(24));

    const gis: number[] = [28,13,7,4,7,13,28];
    for (let i = 0; i < this.grilles.length; i++) {
      const grille = this.grilles[i];
      const gi = gis[i];
      grille.topPx(s(40 + (i * 12))).leftPx(s(gi)).widthPx(bodyW - s(2*gi)).heightPx(s(5));
    }

    this.darkModeButton.size(s(36),s(36)).el.leftPx(bodyW - s(44)).topPx(bodyH - s(80));

    const mainW = bodyW - s(2 * 32);
    this.mainEl.leftPx(s(32)).topPx(s(-4)).widthPx(mainW).heightPx(bodyH + s(8)).borderRadiusPx(s(8));

    this.audio.topPx(s(20)).leftPx(s(20)).widthPx(mainW - s(2 * 20)).heightPx(s(54));

    const buttonH = Math.max(s(36),28);

    this.allSongsButton.size(s(176), buttonH).el.leftPx(s(20)).topPx(s(90));

    this.prevYearButton.size(s(36), buttonH).el.leftPx(mainW - s(20 + 176)).topPx(s(90));
    this.yearButton    .size(s(96), buttonH).el.leftPx(mainW - s(20 + 136)).topPx(s(90));
    this.nextYearButton.size(s(36), buttonH).el.leftPx(mainW - s(20 +  36)).topPx(s(90));

    this.allSongsButton.state((this.year === 0) ? 'active' : 'enabled');
    this.yearButton    .state((this.year !== 0) ? 'active' : 'enabled');


    let y = s(110) + buttonH;
    for (let i = 0; i < this.songButtons.length; i++) {
      const sb = this.songButtons[i];
      sb.size(mainW - s(2 * 20), buttonH).el.leftPx(s(20)).topPx(y);
      const isVisible = (y < (bodyH - s(120)));
      sb.el.display(isVisible ? 'block' : 'none');
      if (isVisible) {
        this.numSongButtons++;
        y += buttonH;
      }
    }

    this.vhLogoImg.leftPx(20).topPx(y + (buttonH/4) + 6);
    this.vhTextDiv.leftPx(130).topPx(y + (buttonH/4) + 46);

    this.pageButton.size(s(120),buttonH).el.leftPx(mainW - s(20 + 120)).topPx(y + (buttonH/4));

    // relist the songs
    const songs = this.songs.songs(this.year);

    this.selectedSongButton = undefined;
    this.maxPages = Math.ceil(songs.length / this.numSongButtons);
    this.page     = Math.min(this.page, this.maxPages - 1);
    //console.log(`#songs = ${songs.length}, nb = ${this.numSongButtons}, mxp = ${this.maxPages}, page = ${this.page}`);

    for (let iButton = 0; iButton < this.numSongButtons; iButton++) {
      const iSong = iButton + (this.page * this.numSongButtons);

      if (iSong < songs.length) {
        const song:       SongInfo = songs           [iSong];
        const songButton: Button   = this.songButtons[iButton];
        songButton.text(song.title).value(song);
        if (song === this.selectedSong) {
          this.selectedSongButton = songButton;
          songButton.state(this.audio.domEl.paused ? 'armed':'active');
        }
        else {
          songButton.state('enabled');
        }
      }
      else {
        this.songButtons[iButton].text('').state('disabled').value(undefined);
      }
    }

    this.pageButton.state((this.maxPages > 1) ? ((this.page === 0) ? 'armed' : 'active') : 'disabled');
  }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public animate() {
    // there's a few things that can flash
    const flashPeriod = 1100;
    const now         = Dttm.now();
    const isFlashOn   = ((Math.trunc((now - this.time0) / flashPeriod) % 2) === 0);

    if (this.selectedSongButton) {
      this.selectedSongButton.animate(isFlashOn);
    }
    this.pageButton.animate(isFlashOn);
  }
}