// -------------------------------------------------------------------------------------------------------------------------------------------------------------
// NOTE: you must manually keep this info in sync with package and native build properties!
// -------------------------------------------------------------------------------------------------------------------------------------------------------------
export class AppBuildInfo {
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public get dateString():             string  { return `Mar 24, 2020`; }
  public get majorVersion():           number  { return 1; }
  public get minorVersion():           number  { return 0; }
  public get buildNumber():            number  { return 0; }
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public get appId():                  string  { return "com.victoryhearts.music.app"; }
  public get appName():                string  { return "weather"; }

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  public get versionString():          string  { return `${this.majorVersion}.${this.minorVersion}`; }
  public get versionStringWithBuild(): string  { return `${this.majorVersion}.${this.minorVersion}.${this.buildNumber}`; }
  public get isProductionVersion():    boolean { return ((this.minorVersion % 2) === 0); }
}
